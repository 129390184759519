.PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-gray-700);
    background-color: var(--bs-custom-white);
    background-clip: padding-box;
    border: 1px solid var(--bs-input-border-color);
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
}
svg {
    height: 30px;
    width: 32px;
}
.PhoneInputCountryIcon {
    /* width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio)); */
    /* height: var(--PhoneInputCountryFlag-height); */
    width: auto;
    box-shadow: none !important;
    height: auto;
}
.PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 26px;
}