.registered-user{
    padding-top: 100px;
}
.title{
    color: #000;
    font-size: 18px;
}
.edit{
    position: absolute;
    top: 2px;
    right: 20px;
    font-size: 18px;
    visibility: hidden;
}
.active img {
    opacity: 0.8;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.active:hover .edit{
    visibility: visible;
}
.allSpashScreen-card{
    /* border: 3px solid black !important; */
    height: 210px !important;
}
.addImg{
    opacity: 0.2;
    height: 69px !important;
    width: 67px !important;
}
.editCategoryImage img{
 height: 140px;
}
.editCategoryImage {
    border: 1px dashed #dbdbdb;
    padding: 12px;
}

