.dragdrop-container{
    align-items: center;
    background-color: hsla(240,5%,96%,.67);
    border: 2px dashed #b1a9b945;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}
.drag-n-drop-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
}
.commonImgs {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
}
.commonImgs img {
    height: 149px;
    object-fit: contain;
    width: 300px;
}